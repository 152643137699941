// Colours
$yellow: #fdda5b;
$orange: #fa5728;
$blue: #4dc7dd;
$green: rgb(56, 221, 108);
$black: #000;
$lightGreen: rgb(213, 248, 229);

// Font
$diaReg: "Dia Regular";
$diaBold: "Dia Bold";
$diaLight: "Dia Light";
$diaBlack: "Dia Black";
$fontColor: $black;