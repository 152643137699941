iframe {
  width: 100% !important;
  max-width: 830px;
  height: 500px !important;
  @media (max-width: 400px) {
    height: 260px !important;
  }
  @media (max-width: 768px) {
    height: 460px !important;
  }
}

body {
  margin: 0;
  font-size: 22px;
  font-family: $diaReg, helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.4s cubic-bezier(0.59, 0.01, 0.28, 1) 0.4s;

  &.home,
  &.page-not-found {
    background-color: $yellow;

    header {
      background-color: $yellow;
    }
  }

  &.people {
    background-color: $orange;

    header {
      background-color: $orange;
    }
  }

  &.philosophy {
    background-color: $green;

    header {
      background-color: $green;
    }
  }

  &.places {
    background-color: $blue;

    header {
      background-color: $blue;
    }
  }

  &.philanthropy {
    background-color: $lightGreen;

    header {
      background-color: $lightGreen;
    }
  }

  &.project {
    background-color: #fff;

    header {
      background-color: #fff;
    }

    ::selection {
      background: black !important;
      color: white;
    }
  }

  &.progress {
    background-color: #e3e3e3;

    header {
      background-color: #e3e3e3;
    }

    ::selection {
      background: black !important;
      color: white;
    }
  }
  &.publications {
    background-color: $yellow;
  
      header {
        background-color: $yellow;
      }
  }
}

.container {
  max-width: 1280px;
}

::selection {
  background: white;
}

a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h2,
h3,
h4,
h5 {
  font-size: inherit;
  font-weight: normal;
  margin: 0;

  span {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &.capitalise {
    span {
      text-transform: capitalize;
      letter-spacing: 0px;
    }
  }

  &.lowercase {
    span {
      text-transform: lowercase;
      letter-spacing: 0px;
    }
  }
}

section {
  margin: 20px 10px 0;
  overflow: hidden;

  &.section--has-margin {
    margin: 0.5em 10px 0;

    @media (min-width: 768px) {
      margin: 80px 10px 0;
    }
  }

  &.contact {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 16px;

    h3 {
      margin-top: 1em;
    }

    @media (min-width: 420px) {
      font-size: 19px;
    }

    @media (min-width: 768px) {
      margin-top: 20px;

      h3 {
        margin-top: 0;
      }
    }
  }

  &.is-fixed {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: calc(100% - 20px);
  }

  & > h3 {
    width: calc(100% - 20px);
    margin: 1em 10px;
  }
}

.nl-form {
  border: 1px solid black;
  height: 1.7em;
  width: 100%;
  position: relative;
  margin: 0.5em 0;

  @media (min-width: 560px) {
    width: 70%;
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  input {
    position: absolute;
    top: 0;
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    height: calc(100%);
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    padding: 0 5px;
    outline: none;
  }

  input[type="email"] {
    left: 0;
    width: calc(100% - 12px);
    z-index: 1;

    &::placeholder {
      color: inherit;
    }
  }

  input[type="submit"] {
    border-left: 1px solid black;
    width: 30%;
    right: 0;
    z-index: 2;

    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
    }
  }

  &:not(.newsletter--active) input[type="submit"] {
    opacity: 0;
    pointer-events: none;
  }

  &.subscribe-complete {
    border: 0;

    input {
      display: none;
    }
  }
}

.col {
  float: left;
  margin: 0 10px;

  &--right {
    float: right;
  }

  &--clear {
    clear: both;
  }

  &--no-top-margin {
    p {
      margin-top: 0 !important;
    }
  }

  &--no-heading {
    p:first-child {
      margin-top: 0;
    }
  }

  &--1 {
    width: calc(16.6666666667% - 20px);
  }

  &--2 {
    width: calc(100% - 20px);

    @media (min-width: 769px) {
      width: calc(33% - 20px);
    }

    @media (min-width: 1080px) {
      width: calc(33.3333333334% - 20px);
    }
  }

  &--3 {
    width: calc(100% - 20px);

    &:first-of-type {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      margin-bottom: 0;
      width: calc(50.0000000001% - 20px);
    }
  }
  &--3-progress {
    width: calc(100% - 20px);
    margin: 0;
    margin-right: 20px;

    &:first-of-type {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      margin-bottom: 0;
      width: calc(50.0000000001% - 20px);
    }
  }
  &--4 {
    width: calc(100% - 20px);

    @media (min-width: 1080px) {
      width: calc(66.6666666668% - 20px);
    }
  }

  &--5 {
    width: calc(100% - 20px);

    @media (min-width: 421px) {
      width: calc(83.3333333335% - 20px);
    }
  }

  &--6 {
    width: calc(100% - 20px);
  }

  p {
    padding-right: 20px;
    margin-top: 0;

    @media (max-width: 768px) {
      margin: 0.2em 0 1em;
    }

    &.large {
      font-size: 32px;
      margin: 0.5em 0 1em;
      line-height: 0.99em;

      @media (min-width: 321px) {
        font-size: 32px;
        letter-spacing: -1px;
      }

      @media (min-width: 420px) {
        font-size: 52px;
      }

      @media (min-width: 769px) {
        font-size: 72px;

        &--no-top-margin {
          margin-top: 0;
        }
      }
    }

    &.is-quote {
      margin: 20px 0;
      padding-left: 0.5em;

      @media (min-width: 768px) {
        margin: 0 0 20px;
      }

      &:before {
        content: "“";
        margin-left: -0.5em;
      }
    }

    &.img-caption {
      margin: 10px 0 0;
      font-size: 19px;
    }

    &.small-print {
      font-size: 14px;
    }

    a {
      text-decoration: none;
      border-bottom: 1px solid;
      transition: border 0.3s ease;

      &:hover {
        border: 0;
      }
    }
  }

  .credit {
    margin-right: 32px;
    font-size: 18px;
    display: inline-block;
    line-height: 1em;
    vertical-align: middle;

    &--large {
      margin-top: 1em;
      font-size: 22px;
      display: block;
    }
  }
}
.publications {
  display: flex !important;

  @media (max-width: 420px) {
   flex-direction: column;
  }
}

.publication-articles-container  {
  .disable {
    display: none;
  }
  .publication-entry {
    cursor: pointer;
  }
  .has-rule {
    border-top: 1px solid $black;
    padding-bottom: .75rem;
  }
  .publication-post-wrapper
  {
    display: flex;
    h4 {
      width: 90%;
    }
    p {
      width: 100%;
      text-align: right;
    }
    a 
    {
      width: 50%;
      text-align: right;
      padding-right: 20px;
    }
    div {
      width: 10%;
      text-align: right;
      p {
        padding: 0;
      }
    }
  }
}
.hide-on-mobile {
  @media (max-width: 420px) {
    display: none;
  }
}

.has-rule {
  border-top: 1px solid $black;
}

.has-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.no-left-margin {
  margin-left: 0;
}

main {
  margin: 0 0 40px 0;
  overflow: hidden;
}

.breadcrumb {
  p {
    margin: 0;

    a {
      // text-decoration: underline;
      text-decoration: none;
      border-bottom: 1px solid;
      transition: border 0.3s ease;

      &:hover {
        border: 0;
      }
    }
  }
}

.img-wrapper {
  width: 100%;
  position: relative;

  &--6x4 {
    padding-bottom: 66.6666666667%;
  }

  &--16x9 {
    padding-bottom: 56.2380952381%;
  }

  &--portrait-long {
    padding-bottom: 150%;
  }

  &--portrait-short {
    padding-bottom: 108.764940239%;
  }
  &--hero-progress {
    padding-bottom: 66.0793804453%;

    img {
      height: auto !important;
    }
  }
  &--hero {
    padding-bottom: 46.0793804453%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    transition: opacity 0.6s ease;
  }

  &.inview-image {
    img {
      opacity: 0;
    }
  }

  &.image-loaded.image-inview {
    img {
      opacity: 1;
    }
  }
}

.btt-wrap {
  position: fixed;
  bottom: 1rem;
  float: right;
  z-index: 2;
  right: 40px;
  bottom: 40px;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }

  .btt {
    display: block;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    height: 3.6363636364rem;
    width: 3.6363636364rem;
    text-align: center;
    line-height: 3.6363636364rem;
    border-radius: 50%;
    overflow: hidden;
  }
}

.progress-posts {
  margin-top: 120px;
  display: block;

  @media (min-width: 768px) {
    //margin-top: 160px;
  }

  @media (min-width: 1281px) {
    //margin-top: 215px;
  }
}

#load-more {
  visibility: hidden;
  height: 0;
}

.table {
  &,
  tbody,
  thead,
  tr,
  td,
  th {
    box-sizing: border-box;
  }

  tr {
    border-bottom: 1px black solid;
    display: block;

    &:last-child {
      border-bottom: none;
    }

    th,
    td {
      display: inline-block;
    }

    td:nth-child(1),
    td:nth-child(2),
    th:nth-child(1),
    th:nth-child(2) {
      width: 25%;
    }

    td:nth-child(3),
    th:nth-child(3) {
      width: 48%;
    }
  }

  td,
  th {
    padding: 10px;
    padding-left: 0;
    padding-right: 30px;

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    text-align: left;
  }
}
