.standfirst {
  margin: 120px 20px 0;
  display: block;

  @media (min-width: 768px) {
    margin: 160px 20px 0;
  }

  @media (min-width: 1281px) {
    margin: 215px 20px 0;
  }

  &__page-title {
    span {
      text-transform: uppercase;
      font-size: inherit;
      font-weight: normal;
      letter-spacing: 2px;
      margin: 0;
    }
  }

  &__paragraph {
    margin: 0 0 20px;
    font-size: 26px;
    line-height: 0.99em;
    max-width: 1280px;
    letter-spacing: -0.5px;

    @media (min-width: 321px) {
      font-size: 32px;
      letter-spacing: -1px;
    }

    @media (min-width: 560px) {
      font-size: 52px;
    }

    @media (min-width: 769px) {
      font-size: 72px;
    }

    @media (min-width: 1400px) {
      font-size: 88px;
    }

    a {
      color: $black;
      display: inline-block;
      border-bottom: 2px solid $black;
      text-decoration: none;
      line-height: 0.9em;
      transition: text-shadow .4s cubic-bezier(.59,.01,.28,1) .4s;

      @media (min-width: 768px) {
        line-height: 0.7em;
        border-bottom: 5px solid $black;
      }
    }
  }

  &__link {
    &:hover {
      @media (min-width: 768px) {
        .standfirst__thumbnail {
          display: block;
        }
      }
    }
  }

  &__thumbnail {
    position: fixed;
    z-index: 9999;
    display: none;
    width: 50%;
    max-width: 420px;
    max-height: 280px;
    top: 0;
    left: 0;
    
    &__images {
      position: relative;
      display: block;
      padding-bottom: 82.0238095238%;
    }

    &.is-transitioning {
      transition: all .2s ease;
    }

    h4 {
      z-index: 3;
      position: absolute;
      top: 0;
      background-color: #fff;
      font-size: 22px;
      text-shadow: none;
      display: block;
      padding-left: 10px;
      width: calc(100% - 10px);
      line-height: 2.5em;
    }

    &__img {
      z-index: 2;
      position: absolute;
      font-size: 0;
      margin: 0 !important;
      max-width: 420px !important;
      max-height: 280px !important;

      &.hidden {
        display: none;
      }
    }
  }

}