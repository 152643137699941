header {
  position: absolute;
  width: calc(100% - 40px);
  max-width: 1240px;
  top: 0;
  left: 20px;
  right: 20px;
  // margin: auto;
  z-index: 8888;
  transition: background-color .4s cubic-bezier(.59,.01,.28,1) .4s;

  @media (min-width: 769px) {
    top: 2px;
  }

  &.mobile-menu-is-open {
    .mobile-menu {
      &__inner {
        transform: translateY(0%);
      }
    }

    .navigation {
      &__list {
        transform: translateY(0%);
      }
    }
  }

  h1 {
    font-weight: normal;
    letter-spacing: 2px;
    font-size: inherit;
    float: left;
    margin: 20px 0;

    a {
      text-decoration: none;
      outline: 0;
    }
  }

  .mobile-menu {
    float: right;
    font-size: inherit;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
    height: 1em;
    cursor: pointer;

    @media (min-width: 769px) {
      display: none;
    }

    &__inner {
      transform: translateY(-50%);
      transition: transform .4s cubic-bezier(.59,.01,.28,1);

      span {
        display: block;
      }
    }
  }

  .title-wrapper {
    @media (max-width: 768px) {
      z-index: 8888;
      position: relative;
      overflow: hidden;
      transition: background-color .4s cubic-bezier(.59,.01,.28,1) .4s;
      background-color: white;
  
      .home & {
        background-color: $yellow;
      }
  
      .places & {
        background-color: $blue;
      }
  
      .philosophy & {
        background-color: $green;
      }
  
      .people & {
        background-color: $orange;
      }

      .philanthropy & {
        background-color: $lightGreen;
      }

      .project & {
        background-color: white;
      }

      .progress & {
        background-color: #e3e3e3;
      }
      .publications & {
        background-color: $yellow;
      }
    }
  }

  .navigation {
    float: right;
    
    &__list {
      margin: 20px 0;
      list-style: none;
      padding: 0;
      overflow: hidden;

      @media(max-width: 768px) {
        position: absolute;
        top: 66px;
        left: 0;
        width: 100%;
        margin: 0;
        transform: translateY(-115%);
        transition: transform .4s cubic-bezier(.59,.01,.28,1), background-color .4s cubic-bezier(.59,.01,.28,1) .4s;
        background-color: white;

        .navigation__list__item {
          width: 100%;
          display: block;
          float: none;
          margin: 0;
          border-bottom: 1px solid $black;

          a {
            @media(max-width: 768px) {
              float: none;
              padding: 20px 0;
              display: block;
            }
          }
        }

        .home & {
          background-color: $yellow;
        }
    
        .people & {
          background-color: $orange;
        }
    
        .places & {
          background-color: $blue;
        }
    
        .philosophy & {
          background-color: $green;
        }

        .philanthropy & {
          background-color: $lightGreen;
        }
        
        .project & {
          background-color: white;
        }

        .progress & {
          background-color: #e3e3e3;
        }
        .publications & {
          background-color: $yellow;
        }
    
      }

      &__item {
        float: left;
        margin-left: 20px;

        a {
          text-decoration: none;
          color: $black;
        }
      }
    }
  }

  .rule {
    float: left;
    width: 100%;
    height: 1px;
    background-color: rgba(0,0,0,0.2);
    position: relative;

    #active-page-rule {
      height: 1px;
      background: $black;
      position: absolute;
      top: 0;
      transition: all .4s cubic-bezier(.59,.01,.28,1);
      width: 0;
      left: 0;

      @media(min-width: 768px) {
        &.home {
          width: 122px;
          left: 0;
        }
  
        &.people {
          right: 348px;
          width: 65px;
        }
  
        &.places {
          right: 265px;//367px
          width: 62px;
        }
  
        &.philosophy {
          right: 139px;
          width: 105px;
        }

        &.philanthropy {
          right: 0;
          width: 120px;
        }

        &.progress {
          right: 0;
          width: 83px;
        }
      }
    }
  }
}
