.team {

  &__members {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;

    &__member {
      border-bottom: 1px solid $black;
      float: left;
      width: 100%;

      &:hover {
        cursor: pointer;

        .no-touchevents & .team__members__member__bio__img {
          opacity: 1;
        }
      }

      &.has-bio {
        .team__members__member__header {
          &:after {
            content: "+";
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      &.has-bio.is-open {
        .team__members__member__header {
          &:after {
            content: "–";
          }
        }

        .team__members__member__bio {
          display: block;
        }

        .touchevents & .team__members__member__bio__img {
          position: static !important;
          opacity: 1;
        }
      }

      &:first-child {
        .team__members__member__header {
          h4 {
            margin-top: 0 !important;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &__header {
        width: 100%;
        display: block;
        margin-right: 30px;
        position: relative;

        h4 {
          overflow: hidden;
          margin: 15px 0;
        }

        span {
          float: left;
          width: calc(50% - 30px);

          &:last-child {
            text-transform: capitalize;
            letter-spacing: 0;
            margin-left: 45px;
          }
        }
      }

      &__bio {
        width: calc(50% - 15px);
        width: calc(100%);
        float: right;
        display: none;
        position: relative;
        padding-bottom: 1em;

        p {
          margin: 0 0 1em !important;

          @media (min-width: 768px) {
            padding-top: 7px;
          }
        }

        a {
          text-decoration: underline;

          &:hover {
            tex-decoration: none;
          }
        }

        &__img {
          // .no-touchevents & {
            width: calc(20%) !important;
            height: auto;
            position: fixed;
            // left: calc(-50% - 10px);
            top: 0;
            opacity: 0;
            z-index: 99;
            pointer-events: none;
          // }

        }
      }
    }
  }
}