.breadcrumb {
  margin-top: 86px;

  // @media (max-width: 768px) {
  //   margin-top: 30.962962963vw;
  // }

  &__link {
    @media (max-width: 768px) {
      margin: 0 !important;
    }
  }
}

.project-meta {
  margin-top: 20px;

  &__image {
    max-width: 830px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
  }
  &__title {
    padding-right: 20px;
    font-size: 26px;
    line-height: 0.99em;
    max-width: 830px;
    letter-spacing: -0.5px;
    display: flex;

    @media (min-width: 321px) {
      font-size: 32px;
      letter-spacing: -1px;
    }

    @media (min-width: 420px) {
      font-size: 52px;
    }

    @media (min-width: 769px) {
      font-size: 72px;
      margin-bottom: 20px;
    }
  }
  &__title-progress {
    padding-right: 20px;
    font-size: 26px;
    line-height: 0.99em;
    max-width: 830px;
    letter-spacing: -0.5px;
    padding-top: 20px;
    display: flex;
    width: calc(100% - 40px);
    @media (min-width: 321px) {
      font-size: 32px;
      letter-spacing: -1px;
    }
    @media (max-width: 420px) {
      padding-top: 0px;
    }
    @media (min-width: 420px) {
      font-size: 52px;
    }

    @media (min-width: 769px) {
      font-size: 72px;
      //margin-bottom: 20px;
    }
  }
  p {
    max-width: 830px;
    width: calc(100% - 40px);
  }

  ul {
    list-style: none;
    padding: 0;
  }
  // &__list-progress {
  //   //display: contents;
  // }
  &__list-progress,
  &__list {
    li {
      padding-left: 1.2em;

      &:before {
        content: "—";
        margin: 0 5px 0 -1.3em;
      }
    }
  }
}
