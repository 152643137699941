main {
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity .4s cubic-bezier(.59,.01,.28,1) .5s;
  }

  .home-footer {
    background-color: $yellow;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 40px);

    &__email {
      float: left;
    }

    &__number {
      float: left;

      @media (min-width: 420px) {
        float: right;
      }
    }
  }
}