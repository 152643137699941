.legal-channel {
  margin: 120px 10px 0;
  font-size: 16px;

  @media (min-width: 768px) {
    margin: 160px 10px 0;
    font-size: 22px;
  }

  @media (min-width: 1281px) {
    margin: 215px 10px 0;
  }

  h3 {
    margin-top: 0;
  }

  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0 0 1em !important;

    & > li {
      display: table;

      &:before {
        content: "—";
        display: table-cell;
        width: 1.5em;
        text-align: left;
      }
    }
  }

  ol > li {
    counter-increment: orderedListCounter;
    list-style: none;
  }
  ol > li:before {
    content: counter(orderedListCounter) ".";
  }

  .col {
    counter-reset: orderedListCounter;
    h3 {
      border-top: 1px solid;
      line-height: 1.2em;
      margin: 1em 0;
      padding: 0.5em 0;
    }
  }
}
