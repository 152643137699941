.text-and-image-combo-projects {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    margin-top: 60px !important;
    height: 650px;
  }
}

.text-and-image-combo-projects,
.text-and-image-combo {
  margin-top: 20px;
  transform: translateY(10%);
  transition: transform 0.6s ease;

  @media (max-width: 768px) {
    .col--2 {
      width: 100%;
    }

    .col--right {
      float: left;
    }
  }

  @media (min-width: 768px) {
    margin-top: 120px;
    //height: 650px;
  }

  &:nth-child(even):not(.text-and-image-combo--not-alternating) {
    .text-and-image-combo__img {
      float: right;
    }
  }

  &:nth-child(odd):not(.text-and-image-combo--not-alternating) {
    .text-and-image-combo__text {
      float: right;
      @media (max-width: 768px) {
        float: none;
      }
    }
  }

  &.section-inview {
    transform: translateY(0);

    .text-and-image-combo__text,
    .text-and-image-combo__img {
      opacity: 1;
    }
  }

  &__text {
    opacity: 0;
    padding-top: 1.5em;
    transition: opacity 1.5s ease;

    h4 {
      margin-bottom: 1em;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      //SHOOT ME PLEASE.
      &.a-hack {
        text-decoration: none;
        display: block;
      }
    }

    @media (min-width: 768px) {
      border-top: 1px solid $black;
    }

    &--no-heading {
      p:first-child {
        margin-top: 0;
      }
    }
  }

  &__img {
    opacity: 0;
    transition: opacity 0.6s ease;

    img {
      width: 100%;
      margin: 0;
    }

    &__caption {
      margin: 10px 0 0 !important;
      font-size: 19px;
    }
  }
}
