.project-grid {
  margin: 120px 10px 0;

  @media (min-width: 768px) {
    margin: 160px 10px 0;
  }

  @media (min-width: 1281px) {
    margin: 215px 10px 0;
  }

  &__project {
    display: block;

    @media (max-width: 768px) {
      margin-top: 20px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      clear: both;
    }

    &__title {
      font-size: 32px;
      margin: 0 !important;
      line-height: 0.9em;
      max-width: 600px;
      letter-spacing: -1px;

      @media (min-width: 768px) {
        margin: 0 0 10px 0;
      }

      @media (min-width: 920px) {
        font-size: 42px;
        margin: 0 40px 0px 0;
      }

      @media (min-width: 1024px) {
        font-size: 60px;
        margin: 0 40px 0px 0;
      }
    }
  }

  .img-wrapper {
    margin-bottom: 0;
    
    img {
      width: auto;
      max-width: 100%;
      height: 100%;
    }
  }

}